<template>
    <div>
        <row ref="row" :base="$options.name"></row>
    </div>
</template>

<script>
    import row from '@/components/row.vue'

    export default {
        name: "tuanLian",
        components:{
            row
        },
        data() {
            return {
                list:[
                    {
                        title: "使用前检查",
                        text:[
                            "#初始环境必须成功(图1)",
                        ],
                        img:[
                            "com/init.png",
                        ]
                    },
                    {
                        title: "功能说明",
                        text:[
                            "#切换到团练页",
                            "#在\"加入团练\"后开始程序",
                            "#或者在\"团练附近\"开始程序,通过聊天自动申请加入团练",
                        ],
                        img:[
                            "1.png",
                        ]
                    },
                    {
                        title: "游戏说明",
                        text:[
                            '#角色移动到"团练附近"或者"加入团练后",开始任务',
                            '#"团练附近"会通过聊天自动申请加入团练',
                        ],
                        img:[
                            "2.png",
                            "3.png",
                        ]
                    },
                ],
            }
        },
        methods:{

        },
        mounted() {
            this.$refs.row.initData(this.list);
        }
    }
</script>

<style scoped>

</style>
